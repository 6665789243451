import React from 'react';
import { graphql } from 'gatsby';
import SearchForm from '../../components/SearchForm.js'
import WhitelabelSearchForm from '../../components/WhitelabelSearchForm.js'
import CopyImageBlock from '../../components/CopyImageBlock.js'
import LinkBlock from '../../components/LinkBlock.js'
import LinkList from '../../components/LinkList.js'
import ImageGrid from '../../components/ImageGrid.js'
import BlogList from '../../components/BlogList.js'
import Hero from "../../components/Hero.js";
import TrustPilot from "../../components/TrustPilot";
import WhitelabelHero from "../../components/WhitelabelHero.js";
import Accordion from "../../components/Accordion";


export default function PPCPage({data}) {

	let ppc = [];

	if(data.ppc) {
		ppc = data.ppc.pageBuilder || [];
	}

	const content = (ppc || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
		case "searchForm":
			el = <SearchForm key={c._key} {...c} />;
		  break;
		case "hero":
			el = <Hero key={c._key} {...c} ppc={true}/>;
			  break;
		case "whitelabelHero":
			el = <WhitelabelHero key={c._key} {...c} aid={data.settings.aid}/>;
			break;
		case "whitelabelSearchForm":
			el = <WhitelabelSearchForm key={c._key} {...c}  aid={data.settings.aid}/>;
		  break;
		case "copyImageBlock":
			el = <CopyImageBlock key={c._key} {...c} />;
		  break;
		case "linkBlock":
			el = <LinkBlock key={c._key} {...c} />;
		  break;
		case "linkList":
			el = <LinkList key={c._key} {...c} />;
		  break;
		case "imageGrid":
			el = <ImageGrid key={c._key} {...c} />;
          break;
		case "blogList":
			el = <BlogList key={c._key} {...c} />
          break;
		case 'trustPilot':
			el = <TrustPilot key={c._key} {...c} />
		  break;
	    case 'accordion':
		    el = <Accordion key={c._key} {...c} />
		  break;
        default:
          el = null;
      }
      return el;
    });

	return (
		<>
			<section className="page pad">
				{content}
			</section>
		</>
	);
}

export const query = graphql`
	query {
		ppc: sanityPpcpage(_id: {eq: "LWHTppcpage"}) {
			titleSEO
			descriptionSEO
			keywordsSEO
			pageBuilder {
				... on SanityWhitelabelHero {
                    _key
                    _type
                    heading
                    tagline
                    searchForm {
						_key
						_type
						color
						description
						redirectOption
						heading
						default
						override
						overrideDate
						overrideNights
						overrideGuests
						tagline
						hotTubFilter
						vrSort
						apartmentFilter
						holidayHomesFilter
						lodgeFilter
						hotelFilter
						poolFilter
						luxuryFilter
						coastalFilter
						wifiFilter
						petsFilter
                    }
                    alignText
                    image {
                        asset {
fluid(maxWidth: 800, maxHeight: 440) {
                                    ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
				... on SanityHero {
                    _key
                    _type
                    heading
                    tagline
                    searchForm {
						apartmentFilter
						color
						beachFilter
						cottageFilter
						description
						gardenFilter
						heading
						hottubFilter
						lodgeFilter
						luxuryFilter
						wifiFilter
						parkingFilter
						fireFilter
						babyFilter
						poolFilter
						gamesFilter
						enclosedFilter
						pubFilter
						secludedFilter
						accessibleFilter
						override
						overrideDate
						overrideNights
						overrideGuests
						petFilter
						tagline
                    }
                    alignText
                    image {
                        asset {
fluid(maxWidth: 800, maxHeight: 440) {
                                    ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
				... on SanitySearchForm {
					_key
					_type
					apartmentFilter
					color
					beachFilter
					cottageFilter
					description
					gardenFilter
					heading
					hottubFilter
					lodgeFilter
					luxuryFilter
					wifiFilter
					parkingFilter
					fireFilter
					babyFilter
					poolFilter
					gamesFilter
					enclosedFilter
					pubFilter
					secludedFilter
					accessibleFilter
					override
					overrideDate
					overrideNights
					overrideGuests
					petFilter
					tagline
				}
				... on SanityWhitelabelSearchForm {
					_key
					_type
					color
					description
					heading
					redirectOption
					default
					override
					overrideDate
					overrideNights
					overrideGuests
					tagline
					hotTubFilter
					apartmentFilter
					holidayHomesFilter
					lodgeFilter
					hotelFilter
					poolFilter
					luxuryFilter
					coastalFilter
					wifiFilter
					petsFilter
				}
				 ... on SanityTrustPilot {
                    _key
                    _type
                }
				... on SanityCopyImageBlock {
					_key
					_type
					_rawCopy
					left
					link
					linkUrl
					image {
						asset {
							fixed(width: 1000) {
								...GatsbySanityImageFixed
							}
						}
					}
				}
				... on SanityLinkList {
					_key
					_type
					title
					linkItems {
						copy
						link
					}
				}
				... on SanityLinkBlock {
					_key
					_type
					_rawCopy
					left
					link
					linkUrl
					image {
						asset {
							fixed(width: 1000) {
								...GatsbySanityImageFixed
							}
						}
					}
				}
				... on SanityImageGrid {
					_key
					_type
					title
					gridItems {
						copy
						link
						image {
							asset {
								fluid(maxWidth: 1000) {
									...GatsbySanityImageFluid
								}
							}
						}
					}
				}
				... on SanityBlogList {
					_key
					_type
					subtitle
					title
					blogposts {
						image {
							asset {
								fluid(maxWidth: 1000) {
									...GatsbySanityImageFluid
								}
							}
						}
						slug {
							current
						}
						title
					}
				}
				... on SanityAccordion {
					_key
					_type
					accordions {
						title
_rawCopy
					}
				}
			}
		}
		settings: sanitySiteSettings(_id: {eq: "LWHTsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 400, width: 400) {
					base64
					srcWebp
					srcSetWebp
				}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
			aid
		}
		regions: allSanityLwhTregionPage {
			nodes {
				slug {
					current
				}
				name
				showInNav
			}
		}
		themes: allSanityLwhTtheme {
			nodes {
				name
				slug {
					current
				}
				showInNav
			}
		}
	}
`
